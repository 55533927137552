.AwaitingConfirmation__form-container {
  margin: 5% auto 50px;
  text-align: center;

  @include breakpoint(mobile) {
    margin: 0;
  }
}

.AwaitingConfirmation__form-section {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  max-width: 660px;
  margin-bottom: 20px;
  padding: 35px 40px;
  border-radius: 6px;
  background-color: $color-white;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .15);
  text-align: center;

  @include breakpoint(mobile) {
    width: 100%;
    min-width: auto;
    padding: 35px 25px;
    border-radius: 0;
    box-shadow: none;
  }
}

.AwaitingConfirmation__form-paragraph {
  padding: 8px 0;
}
