.details__actions__enlarge-body {
  @include breakpoint(desktop) {
    overflow: hidden;
  }
}

.details__actions__table {
  overflow-y: auto;
  scrollbar-color: var(--scrollbar-handle-color) var(--scrollbar-track-color);
  scrollbar-width: thin;

  .details__actions__table-outer--enlarged & {
    overflow: auto;
    max-height: 100%;
  }

  // width
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  // Track
  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  // Handle
  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-handle-color);
    border-radius: 16px;
  }

  // Handle on hover
  &::-webkit-scrollbar-thumb:hover {
    background: #0e443f;
  }

  .JobList__wrapper {
    //background-color: var(--scrollbar-track-color);
    width: max-content;
    margin-top: 16px;

    .details__actions__table-outer--enlarged & {
      margin-top: 0;
    }
  }

  .MuiTableCell-root {
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
      background: linear-gradient(
        to left,
        rgba(224, 224, 224, 1) 1px,
        var(--details-table-row-color) 1px
      );
    }
  }

  .MuiTableRow-head {
    --details-table-row-color: rgb(255, 255, 255);

    .JobList__header-cell.MuiTableCell-root {
      background: linear-gradient(
        to top,
        rgba(224, 224, 224, 1) 1px,
        var(--details-table-row-color) 1px
      );
    }

    .MuiTableCell-root {
      &:first-child {
        z-index: 3;
      }
    }
  }

  .JobList__row {
    background-color: var(--details-table-row-color);

    &:nth-child(odd) {
      --details-table-row-color: rgb(240, 240, 240);
    }

    &:nth-child(even) {
      --details-table-row-color: rgb(255, 255, 255);
    }
  }
}

.details__actions__table-outer {
  position: absolute;
  max-width: calc(100% - 32px);

  @include breakpoint(until-desktop) {
    position: relative;
    max-width: none;
  }

  @include breakpoint(desktop) {
    &.details__actions__table-outer--enlarged {
      position: fixed;
      top: calc(50% + 24px);
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1300;
      max-height: calc(100% - 60px);
      display: flex;
      border-radius: 4px;
      background: transparent;
    }
  }
}

.details__actions__enlarge-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  background-filter: blur(10px);
  opacity: 0;
  pointer-events: none;
  transition: all 0.25s ease;
  z-index: 1200;

  @include breakpoint(until-desktop) {
    display: none;
  }

  &.details__actions__enlarge-overlay--show {
    opacity: 1;
    pointer-events: all;
  }
}

.details__actions__enlarge-close {
  position: fixed;
  top: 16px;
  right: 24px;
  cursor: pointer;
  border: none;
  font-weight: 700;
  width: 30px;
  height: 30px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $color-brand;
  transition: all 0.15s ease;

  &:hover {
    background: $color-brand;
    color: #fff;
  }
}

.details__actions__enlarge {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-48%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: all 0.25s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  text-align: center;
  border: none;
  background: linear-gradient(
    to right,
    $color-brand 50%,
    $color-brand-dark 100%
  );
  padding: 24px;
  border-radius: 24px 0 0 24px;
  text-transform: uppercase;

  @include breakpoint(until-desktop) {
    display: none;
  }

  .details__actions__table-outer:hover & {
    opacity: 1;
    pointer-events: all;
    transform: translate(-100%, -50%);
  }

  .details__actions__table-outer--enlarged & {
    display: none;
  }

  span {
    color: $color-white;
    font-weight: bold;
    font-size: 1.25rem;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: inline-block;
      background-color: $color-white;
      width: 24px;
      height: 24px;
      margin-right: 6px;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      -webkit-mask-size: contain;
      -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3e%3cpath fill='black' d='m12.306 16.593-.035 2-6.999-.122.123-7 2 .036-.063 3.585 7.894-7.624-3.532-.061.035-2 6.999.122-.123 7-2-.036.064-3.638-7.948 7.676 3.585.062Z'/%3e%3c/svg%3e");
    }
  }
}
