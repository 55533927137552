.JobDetails__wrapper.MuiPaper-rounded {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 602px;
  box-shadow: none;

  @include breakpoint(until-desktop) {
    min-height: 434px;
  }
}
