h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

h6 {
  font-size: $font-size-h6;
}

p {
  line-height: 1.5;
}

ul,
ol {
  @include reset-list();
}

ul li {
  display: inline-block;
  position: relative;
  padding-left: 15px;

  &::before {
    content: '•';
    display: inline-block;
    position: absolute;
    left: 0;
  }

  &.no-list-style::before {
    content: '';
    display: none;
  }

  &:nth-child(n+2) {
    margin-top: 10px;
  }
}

ol li:not(.MuiBreadcrumbs-li):not(.MuiBreadcrumbs-separator) {
  margin-left: 20px;
  counter-increment: (li);

  &::before {
    content: '.'counter(li);
    display: inline-block;
    width: 20px;
    margin-right: 8px;
    margin-left: -20px;
    text-align: right;
    direction: rtl;
  }

  &:nth-child(n+2) {
    margin-top: 10px;
  }
}

blockquote {
  position: relative;
  font-style: italic;

  &::before {
    content: '“';
    position: absolute;
    top: -12px;
    left: -42px;
    opacity: .1;
    font-family: 'Times New Roman', Times, serif;
    font-size: 75px;
    font-weight: bold;
    pointer-events: none;

    @include breakpoint(tablet) {
      top: -36px;
      left: -48px;
      opacity: .2;
      font-size: 95px;
    }
  }
}

a {
  display: inline-block;
  color: $color-brand;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
