.AppBar__content {
  background-color: $color-brand;
  background-image: linear-gradient(45deg, rgba($color-brand, 1) 0%, rgba($color-brand-dark, 1) 100%);

  @include breakpoint(until-desktop) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 0;
  }
}

.AppBar__content--center {
  text-align: center;
}

.AppBar__title {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;

  @include breakpoint(until-desktop) {
    flex: 0 0 100%;
    flex-direction: column;
    justify-content: center;
  }

  &.MuiTypography-h6 {
    font-weight: 300;
  }
}

.AppBar__title-logo {
  margin-right: 5px;
  font-weight: bold;

  img {
    height: 18px;
    margin-right: 15px;
  }
}

.AppBar__title-link {
  display: inline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.AppBar__actions {
  & > button {
    margin: 5px;
  }

  @include breakpoint(until-desktop) {
    margin-top: 5px;
    text-align: center;
  }
}
