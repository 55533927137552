.details__columns {
  width: 100%;
  padding-top: 32px;

  .Viewport__content:not(.details__main-wrapper) & {
    display: flex;
    align-items: flex-start;

    @include breakpoint(until-desktop) {
      display: block;
    }
  }

  .Viewport__content.details__main-wrapper & {
    --btn-color: #3f51b5;
    --scrollbar-handle-color: #3eaea4;
    --scrollbar-track-color: #f7f7f7;
    //

    @include breakpoint(desktop) {
      display: grid;
      width: 100%;
      grid-template-columns: minmax(0, 70%) minmax(0, 30%);
      gap: 40px;

      .details__column:nth-child(2) {
        background: #fafafa;
        padding: 16px;
        border-radius: 16px;
      }
    }

    @include breakpoint(desktop-xlarge) {
      grid-template-columns: minmax(0, 65%) minmax(0, 35%);
    }

    .details__column:nth-child(2) {
      position: relative;
    }

    .details__side-link {
      display: inline-block;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      a {
        padding: 8px 16px;
        color: var(--btn-color);
        background-color: #fff;
        border: 1px solid var(--btn-color);
        border-radius: 90px;
        transition: ease-in 0.15s;

        &:hover {
          text-decoration: none;
          color: #fff;
          background-color: var(--btn-color);
        }
      }
    }
  }
}

.details__column {
  .Viewport__content:not(.details__main-wrapper) & {
    &:first-child {
      flex: 1 1 auto;

      @include breakpoint(desktop) {
        max-width: calc(100% - 220px);
      }
    }

    &:last-child {
      flex: 1 0 auto;
      max-width: 220px;

      @include breakpoint(until-desktop) {
        width: 100%;
        max-width: none;
        margin: 64px 0;
        text-align: center;
      }
    }
  }
}

.details__sequence {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 16px;
}

.details__aminoacid {
  border: 0;
  background: none;
  font-size: 32px;
  text-align: center;
  user-select: text;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: rgba($color-header-update, 0.3);
  }

  &.details__aminoacid--selected {
    background: $color-grey1;
  }

  &.details__aminoacid--amyloid {
    color: $color-brand;

    &:hover {
      background: $color-header-update;
      color: $color-white;
    }

    &.details__aminoacid--selected {
      background: $color-brand;
      color: $color-white;
    }
  }
}

.details__area {
  margin-top: 48px;
}

.details__chart {
  margin-top: 32px;
  overflow-x: auto;

  @media (max-width: 701px) {
    width: calc(100vw - 32px) !important;
  }
}

.details__actions {
  margin-top: 10px;
}

.details__side-title {
  &:not(:first-child) {
    margin-top: 32px;
  }

  h5 {
    display: flex;
    align-items: center;
  }
}

.details__side-download {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin: 0;
    width: 24px;
    height: 24px;
  }

  &:hover {
    svg {
      color: $color-brand;
    }
  }
}

.details__side-link {
  display: block;
  padding-left: 0;

  &::before {
    display: none;
  }
}

.details__metrics {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.details__metric {
  &.MuiButton-root {
    // specificity
    display: inline-block;
    margin: 4px;
    text-transform: none;
  }
}

.details__metrics-download {
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;

  svg {
    margin: 0;
    width: 20px;
    height: 20px;
  }

  &:hover {
    color: $color-brand;
  }
}
