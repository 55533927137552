.MuiButton-contained {
  box-shadow: none;

  &.ButtonUpdate-jobs,
  .Home__wrapper & {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }
}
