.About__wrapper {
  @include breakpoint(mobile) {
    text-align: center;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code,
  blockquote,
  ol,
  ul,
  p {
    max-width: 800px;
    margin: 20px auto;
  }

  pre,
  code,
  blockquote {
    margin: 40px auto;
  }

  li {
    display: block;
  }
}
