.Errors__wrapper {
  position: fixed;
  z-index: 10;
  top: 100px;
  left: 50%;
  width: 100%;
  max-width: 990px;
  padding: 10px;
  border: 3px solid $color-red;
  background-color: rgba($color-red, .85);
  color: $color-white;
  font-weight: bold;
  text-transform: uppercase;
  transform: translateX(-50%);
}

.Errors__message {
  padding: 20px 20px 20px 0;
  border-bottom: 1px solid rgba($color-white, .85);
  line-height: 1.5;

  &:last-child {
    border-bottom: 0;
  }
}

.Errors__dismiss {
  display: inline-flex;
  position: absolute;
  top: 8px;
  right: 6px;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: 1px solid $color-white;
  border-radius: 12px;
  background-color: $color-white;
  color: $color-red;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: rgba($color-white, .85);
  }
}
