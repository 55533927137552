.JobList__wrapper.MuiPaper-rounded {
  box-shadow: none;
}

.JobList__header-cell {

  // Specificity...
  &.MuiTableCell-head {
    position: sticky;
    top: 0;
    background-color: $color-white;
    font-size: 1rem;
    font-weight: 700;
  }
}

.JobList__row {
  background-color: $color-white;

  .MuiTableCell-body a {
    word-break: break-word;
  }
}

.JobList__row__titleCell {
  max-width: 330px;

  @include breakpoint(desktop-large) {
    max-width: 500px;
  }
}

.JobList__row__dateCell {
  @include breakpoint(tablet) {
    min-width: 160px;
  }
}
