.Home__wrapper {
  .MuiButton-contained {
    background-color: $color-brand;
    color: $bg-header-btn-action;

    &:hover {
      background-color: $color-header-create;
      color: $bg-header-btn-action;
    }
  }
}

.Home__body {
  max-width: 600px;
  margin-bottom: 20px;
  line-height: 1.4;

  @include breakpoint(mobile) {
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
  }
}

.Home__body-logo {
  color: $color-brand;
  font-size: 36px;
  font-weight: 300;
  letter-spacing: .5rem;
  text-align: center;
  text-transform: uppercase;

  @include breakpoint(desktop) {
    font-size: 46px;
  }

  img {
    width: 100%;
    max-width: 450px;
    height: auto;
    margin: 0 auto 40px;
  }
}
