.Loading__outer-wrapper {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, .65);
  color: $color-brand;
  font-size: $font-size-h1;
}

.Loading__inner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 40px;
  border-top: 3px solid $color-brand;
  border-bottom: 3px solid $color-brand;
  background: $color-white;
  text-align: center;
  transform: translate(-50%, -50%);

  span {
    display: block;
  }
}
