.JobDetails__top-bar {
  margin-bottom: 24px;
}

.ProteinDetails__row {
  display: flex;
}

.ProteinDetails__column {
  width: 50%;
  padding: 12px;

  &:nth-child(1) {
    // padding-right: 15%;
    text-align: center;
    font-weight: 700;
  }

  &:nth-child(2) {
    text-align: center;
  }
}

.Breadcrumbs__wrapper {
  position: absolute;
  left: 0;
  top: 0;
  padding: 12px;
}
