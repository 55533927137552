.MuiButton-contained.Button__logout {
  border: 1px solid $bg-header-btn-action;
  background-color: transparent;
  color: $bg-header-btn-action;

  &:hover {
    border: 1px solid $bg-header-btn-action;
    background-color: $bg-header-btn-action;
    color: $color-header-btn-action;
  }
}
