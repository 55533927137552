.Footer {
  position: relative;
  background-color: $bg-footer;
  background-image: linear-gradient(45deg, rgba($bg-footer, 1) 0%, rgba($color-black, 1) 100%);
  color: $color-white;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    margin-bottom: 10px;
    background: $color-brand;
    pointer-events: none;
  }
}

.Footer__wrapper {
  max-width: 1280px;
  margin: 0 auto;
  padding: 16px;

  @include breakpoint(mobile) {
    text-align: center;
  }

  @include breakpoint(tablet) {
    display: grid;
    grid-gap: 1rem;
    grid-template-areas: 'info info''links contacts';
    grid-template-columns: minmax(250px, 50%) auto;
    padding: 24px;
  }

  @include breakpoint(desktop) {
    grid-template-areas: 'links info''contacts contacts';
    grid-template-columns: minmax(250px, auto) auto;
    padding: 24px 32px;
  }

  @include breakpoint(desktop-large) {
    grid-gap: 2rem;
    grid-template-areas: 'links info contacts';
    grid-template-columns: minmax(250px, auto) auto minmax(300px, auto);
  }
}

.Footer__message {
  grid-area: info;
  max-width: 505px;
  margin: 0 auto;
  font-size: 14px;
  text-align: center;

  p {
    margin: 0;
  }
}

.Footer_links {
  grid-area: links;
  text-align: center;

  @include breakpoint(mobile) {
    margin: 20px auto;
  }

  button.MuiButton-textPrimary {
    padding: 6px 12px;
    border: 1px solid $color-grey1;
    color: $color-white;

    &:hover {
      border: 1px solid $color-brand;
      background: $color-brand;
      color: $color-white;
    }

    &:nth-child(n+2) {
      margin-left: 10px;
    }
  }
}

.Footer__contacts {
  grid-area: contacts;

  @include breakpoint(desktop) {
    justify-content: center;
  }

  @include breakpoint(until-desktop-large) {
    display: flex;
    flex-wrap: wrap;
  }
}

.Footer__contacts__header {
  margin-bottom: 6px;
  color: $color-grey1;

  @include breakpoint(mobile) {
    width: 100%;
  }
}

.Footer__contacts__item {
  &.MuiTypography-root {
    display: block;
    margin: 0 8px 8px;
    color: inherit;
    font-weight: normal;

    @include breakpoint(desktop-large) {
      margin: 0 0 6px;
    }
  }

  @include breakpoint(mobile) {
    width: 100%;
  }
}
