.ngl__wrapper {
  position: relative;
  width: 100%;
  height: 500px;
  margin-top: 32px;
  background-color: $color-white;

  @include breakpoint(until-desktop) {
    height: 300px;
  }

  canvas {
    background-color: transparent !important;
  }
}

.ngl__placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  padding: 1em;
  border: 2px solid $color-brand;
  background-color: rgba($color-brand, .05);
  font-size: 14px;
  transform: translate(-50%, -50%);
}

.ngl__options {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
}

.MuiButton-contained.ngl__options-button {
  background-color: $color-brand;
  color: $color-white;

  &.ngl__options-button--pressed,
  &:hover {
    background-color: $color-header-update;
  }
}

.ngl__options-container {
  padding: 8px 0;
  background: $color-white;
}

.MuiFormControl-root.ngl__options__option {
  width: 100%;
  margin: 8px;
}

li.MuiListItem-root::before {
  display: none;
}
