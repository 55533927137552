[class*='Form__formContainer'] {
  a {
    color: $color-brand;
  }

  button[class*='Button__'] {
    border-radius: 40px;
    background-color: $color-brand;
  }
}
