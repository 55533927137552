.NewJob {
  text-align: center;
}

.NewJob__form {
  width: 600px;
  max-width: calc(100% - 24px);
  margin: 12px;
  text-align: left;
}

.NewJob__title {
  width: 100%;
}

.NewJob__sequence {
  width: 100%;

  textarea {
    text-transform: uppercase;
  }
}

.NewJob__submit.MuiButtonBase-root {
  width: 100%;
  margin: 16px 0 8px;
}

.NewJob__instructions {
  padding-left: 15px;
  font-size: $font-size-small;
  font-style: italic;
  line-height: 1.5;
}

.NewJob__instructions__code {
  font-weight: 600;
}

.NewJob__standalone {
  margin-top: 72px;
  margin-bottom: 24px;
}
