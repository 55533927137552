.collapsible {
  width: 100%;

  .Viewport__content > &:first-child {
    margin-top: 32px;
  }
}

.collapsible__toggle {
  position: relative;
  width: 100%;
  margin: 8px 0;
  padding: 8px 18px 8px 0;
  border: 0;
  border-bottom: 2px solid $color-brand;
  background: none;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
}

.collapsible__toggle-icon {
  position: absolute;
  right: 1px;
  width: 16px;
  height: 16px;
  transform: rotate(90deg);

  .collapsible--shown & {
    transform: rotate(-90deg);
  }
}

.collapsible__content {
  padding: 16px 0;
}
