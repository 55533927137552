// breakpoints
@mixin breakpoint($device) {
  @if $device==desktop-xlarge {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $device==until-desktop-large {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $device==desktop-large {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $device==desktop {
    @media (min-width: 960px) {
      @content;
    }
  } @else if $device==until-desktop {
    @media (max-width: 959px) {
      @content;
    }
  } @else if $device==only-tablet {
    @media (min-width: 600px) and (max-width: 959px) {
      @content;
    }
  } @else if $device==tablet {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $device==mobile {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $device==mobile-small {
    @media (max-width: 440px) {
      @content;
    }
  }
}

//
// List reset
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none outside none;
  list-style-image: none;

  & li {
    list-style: none outside none;
  }
}
