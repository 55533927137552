.form__group {
  margin: 16px 0 8px;
  display: flex;
}

.form__radio-input-text {
  &.MuiFormControl-root { // specificity
    display: block;
    margin-top: 0;
  }

  .MuiInput-formControl {
    width: 100px;
  }
}

.NewJob__upload {
  flex: 1 1 auto;
}

.NewJob__number {
  flex: 1 1 auto;
}
